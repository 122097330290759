<template>
  <v-container
    id="statistics"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-row justify="center">
      <v-col
        v-if="showNewSales"
        cols="12"
      >
        <h3 class="mb-2">
          {{ str['registers_created_sales'] }}
        </h3>
        <div
          v-if="newSalesCurrenciesTotalValuesStr && newSalesCurrenciesAverageValuesStr"
          class="mb-2"
        >
          <v-row>
            <v-col
              cols="6"
              class="pt-dashboard-total-box"
            >
              <base-material-stats-card
                color="green lighten-2"
                icon="mdi-sigma"
                :title="str['total']"
                :value="newSalesCurrenciesTotalValuesStr"
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-dashboard-total-box"
            >
              <base-material-stats-card
                color="green lighten-2"
                icon="mdi-size-m"
                :title="str['average']"
                :value="newSalesCurrenciesAverageValuesStr"
              />
            </v-col>
          </v-row>
        </div>
        <v-data-table
          :headers="salesTableHeaders"
          :items="newSalesTableUsers"
          :search="newSalesTableSearch"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
          :loading="newSalesTableLoading"
          :loading-text="str['processing']"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <div style="width: 100%; display: flex; justify-content: space-between;">
                <div
                  class="mr-4"
                  style="width: 100%; max-width: 500px;"
                >
                  <v-text-field
                    v-model="newSalesTableSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                </div>
                <div class="row-flex-align">
                  <div class="datepicker-element">
                    <v-menu
                      v-model="datesFilter['new_sales'].menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datesFilter['new_sales'].text"
                          :label="str['interval_dates']"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        />
                      </template>
                      <v-date-picker
                        v-model="datesFilter['new_sales'].dates"
                        range
                        style="margin: 0;"
                        @change="datesFilter['new_sales'].callback"
                        :locale="datepickerLanguage"
                      />
                    </v-menu>
                  </div>
                  <v-btn
                    color="secondary"
                    dark
                    class="ml-6"
                    @click="exportSalesTable('newSales')"
                  >
                    {{ str['export'] }}
                  </v-btn>
                </div>
              </div>
              <v-spacer />
            </v-toolbar>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="showReturnSales"
        cols="12"
      >
        <h3 class="mb-2">
          {{ str['registers_return_sales'] }}
        </h3>
        <div
          v-if="returnSalesCurrenciesTotalValuesStr && returnSalesCurrenciesAverageValuesStr"
          class="mb-2"
        >
          <v-row>
            <v-col
              cols="6"
              class="pt-dashboard-total-box"
            >
              <base-material-stats-card
                color="green lighten-2"
                icon="mdi-sigma"
                :title="str['total']"
                :value="returnSalesCurrenciesTotalValuesStr"
              />
            </v-col>
            <v-col
              cols="6"
              class="pt-dashboard-total-box"
            >
              <base-material-stats-card
                color="green lighten-2"
                icon="mdi-size-m"
                :title="str['average']"
                :value="returnSalesCurrenciesAverageValuesStr"
              />
            </v-col>
          </v-row>
        </div>
        <v-data-table
          :headers="salesTableHeaders"
          :items="returnSalesTableUsers"
          :search="returnSalesTableSearch"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
          :loading="returnSalesTableLoading"
          :loading-text="str['processing']"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <div style="width: 100%; display: flex; justify-content: space-between;">
                <div
                  class="mr-4"
                  style="width: 100%; max-width: 500px;"
                >
                  <v-text-field
                    v-model="returnSalesTableSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                </div>
                <div class="row-flex-align">
                  <div class="datepicker-element">
                    <v-menu
                      v-model="datesFilter['return_sales'].menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datesFilter['return_sales'].text"
                          :label="str['interval_dates']"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        />
                      </template>
                      <v-date-picker
                        v-model="datesFilter['return_sales'].dates"
                        range
                        style="margin: 0;"
                        @change="datesFilter['return_sales'].callback"
                        :locale="datepickerLanguage"
                      />
                    </v-menu>
                  </div>
                  <v-btn
                    color="secondary"
                    dark
                    class="ml-6"
                    @click="exportSalesTable('returnSales')"
                  >
                    {{ str['export'] }}
                  </v-btn>
                </div>
              </div>
              <v-spacer />
            </v-toolbar>
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="showAverageEmployeesFeedbacks"
        cols="12"
      >
        <h3 class="mb-2">
          {{ str['average_employees_feedbacks'] }}
        </h3>
        <v-data-table
          :headers="averageEmployeesFeedbacksTableHeaders"
          :items="averageEmployeesFeedbacksData.table"
          :search="averageEmployeesFeedbacksTableSearch"
          :no-data-text="str['no_data']"
          :no-results-text="str['no_data']"
          class="elevation-1"
          :loading="averageEmployeesFeedbacksTableLoading"
          :loading-text="str['processing']"
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <div style="width: 100%; display: flex; justify-content: space-between;">
                <div
                  class="mr-4"
                  style="width: 100%; max-width: 500px;"
                >
                  <v-text-field
                    v-model="averageEmployeesFeedbacksTableSearch"
                    append-icon="mdi-magnify"
                    :label="str['search']"
                    single-line
                    hide-details
                  />
                </div>
                <div class="row-flex-align">
                  <div class="datepicker-element">
                    <v-menu
                      v-model="datesFilter['average_employees_feedbacks'].menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="datesFilter['average_employees_feedbacks'].text"
                          :label="str['interval_dates']"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          hide-details
                        />
                      </template>
                      <v-date-picker
                        v-model="datesFilter['average_employees_feedbacks'].dates"
                        range
                        style="margin: 0;"
                        @change="datesFilter['average_employees_feedbacks'].callback"
                        :locale="datepickerLanguage"
                      />
                    </v-menu>
                  </div>
                </div>
              </div>
              <v-spacer />
            </v-toolbar>
            <v-toolbar
              flat
            >
              <div>
                <div>
                  <b>{{ str['time_avg_all_feedbacks'] }}: {{ averageEmployeesFeedbacksData.total.avg }}</b>
                </div>
                <div>
                  <b>{{ str['time_total_all_feedbacks'] }}: {{ averageEmployeesFeedbacksData.total.time }}</b>
                </div>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:item.time_avg="{ item }">
            {{ item.time_avg_str }}
          </template>
          <template v-slot:item.time_total="{ item }">
            {{ item.time_total_str }}
          </template>
          <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
            {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
          </template>
        </v-data-table>
      </v-col>
      <v-col
        v-if="serviceUseSelectCharts[serviceUseSelectChartActive]"
        cols="12"
        style="padding-bottom: 0;"
      >
        <v-autocomplete
          v-model="serviceUseSelectChartActive"
          :label="str['chart']"
          item-text="title"
          item-value="id"
          :items="serviceUseSelectCharts"
          :no-data-text="str['no_data']"
          hide-details
          style="max-width: 300px"
        />
      </v-col>
      <v-col
        v-if="serviceUseSelectCharts[serviceUseSelectChartActive]"
        cols="12"
        style="padding-top: 5px; padding-bottom: 0;"
      >
        <base-material-chart-card
          :key="serviceUseSelectChartActive"
          :data="serviceUseSelectCharts[serviceUseSelectChartActive]"
          :options="serviceUseSelectCharts[serviceUseSelectChartActive].options"
          :type="serviceUseSelectCharts[serviceUseSelectChartActive].type"
          :color="serviceUseSelectCharts[serviceUseSelectChartActive].color"
          :size="serviceUseSelectCharts[serviceUseSelectChartActive].size"
          :sizelimit="serviceUseSelectCharts[serviceUseSelectChartActive].sizelimit"
          :shadow="true"
          hover-reveal
        >
          <div class="custom-chart-legend">
            {{ serviceUseSelectCharts[serviceUseSelectChartActive].title }}
          </div>
        </base-material-chart-card>
      </v-col>
      <v-col
        v-if="averageSelectCharts[averageSelectChartActive]"
        cols="12"
        class="row-flex-align-space"
        style="padding-bottom: 0;"
      >
        <v-autocomplete
          v-model="averageSelectChartActive"
          :label="str['chart']"
          item-text="title"
          item-value="index"
          :items="averageSelectCharts"
          :no-data-text="str['no_data']"
          hide-details
          style="max-width: 300px"
          @change="datesFilter.average.callback"
        />
        <div class="row-align-right">
          <div class="datepicker-element">
            <v-menu
              v-model="datesFilter.average.menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="datesFilter.average.text"
                  :label="str['interval_dates']"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  hide-details
                />
              </template>
              <v-date-picker
                v-model="datesFilter.average.dates"
                range
                style="margin: 0;"
                @change="datesFilter.average.callback"
                :locale="datepickerLanguage"
              />
            </v-menu>
          </div>
          <v-btn
            color="secondary"
            dark
            class="ml-6"
            @click="exportAverageSelectChart()"
          >
            {{ str['export'] }}
          </v-btn>
        </div>
      </v-col>
      <v-col
        v-if="averageSelectCharts[averageSelectChartActive]"
        cols="12"
        style="padding-top: 5px; padding-bottom: 0;"
      >
        <div
          v-if="chartTotal[averageSelectCharts[averageSelectChartActive].id]"
          class="mt-1"
        >
          {{ str['average'] }}: {{ chartTotal[averageSelectCharts[averageSelectChartActive].id].avg }} | {{ str['total'] }}: {{ chartTotal[averageSelectCharts[averageSelectChartActive].id].count }}
        </div>
        <base-material-chart-card
          :key="averageSelectChartActive"
          :data="averageSelectCharts[averageSelectChartActive]"
          :options="averageSelectCharts[averageSelectChartActive].options"
          :type="averageSelectCharts[averageSelectChartActive].type"
          :color="averageSelectCharts[averageSelectChartActive].color"
          :size="averageSelectCharts[averageSelectChartActive].size"
          :sizelimit="averageSelectCharts[averageSelectChartActive].sizelimit"
          :shadow="true"
          :loading="true"
          hover-reveal
        >
          <div class="custom-chart-legend">
            {{ averageSelectCharts[averageSelectChartActive].title }}
          </div>
        </base-material-chart-card>
      </v-col>
      <v-col
        v-if="charts.length"
        v-for="chart, index in charts"
        :key="index"
        :cols="12"
        style="padding-bottom: 0;"
        class="statistics-clients-charts"
        :class="[chart.cl]"
      >
        <base-material-chart-card
          :data="chart"
          :options="chart.options"
          :type="chart.type"
          :color="chart.color"
          :size="chart.size"
          :sizelimit="chart.sizelimit"
          :shadow="true"
          hover-reveal
        >
          <div class="custom-chart-legend">
            {{ chart.title }}
          </div>
        </base-material-chart-card>
      </v-col>
      <v-col
        v-if="pieCharts.length"
        :cols="12"
        style="padding-bottom: 0;"
        class="statistics-clients-charts"
      >
        <base-material-chart-card
          v-for="chart, index in pieCharts"
          :key="index"
          :data="chart.data"
          :options="chart.options"
          :responsiveOptions="chart.responsiveOptions"
          :type="chart.type"
          :color="chart.color"
          :shadow="true"
          :size="chart.size"
          :sizelimit="chart.sizelimit"
          hover-reveal
        >
          <div class="custom-chart-legend">
            {{ chart.title }}
          </div>
        </base-material-chart-card>
      </v-col>
      <v-col
        v-if="showClientsPortugal && mapProcessing"
        style="display: flex; justify-content: center; align-items: center; height: 500px;"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col
        v-if="showClientsPortugal"
        :cols="12"
      >
        <div id="map-localities"></div>
      </v-col>
    </v-row>

    <v-dialog
      v-if="feedbacksRatingClients"
      v-model="dialogFeedbacksRating"
      persistent
      max-width="750px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ feedbacksRatingClients.title }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col>
                <v-data-table
                  :headers="feedbacksRatingClientsHeaders"
                  :items="feedbacksRatingClients.table"
                  :search="feedbacksRatingClientsSearch"
                  :no-data-text="str['no_data']"
                  :no-results-text="str['no_data']"
                  @click:row="openClient"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <v-text-field
                        v-model="feedbacksRatingClientsSearch"
                        append-icon="mdi-magnify"
                        :label="str['search']"
                        single-line
                        hide-details
                      />
                      <v-spacer />
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      v-if="showChatButton"
                      small
                      class="mr-0"
                      color="secondary"
                      @click.stop="openClientChat(item)"
                    >
                      mdi-chat
                    </v-icon>
                  </template>
                  <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                    {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeFeedbacksRatingDialog"
          >
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .statistics-clients-charts .ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
    font-size: 10px;
  }

  .statistics-clients-charts .v-card--material-chart .v-card--material__heading .ct-chart-pie .ct-label {
    font-size: 10px;
  }

  .statistics-ct-axis-title {
    fill: #fff;
  }

  .statistics-chart-large-cols .ct-bar {
    stroke-width: 15%;
  }

  .statistics-chart-large-cols .ct-bar:nth-child(1) {
    stroke: #ac92eb !important;
  }

  .statistics-chart-large-cols .ct-bar:nth-child(2) {
    stroke: #4fc1e8 !important;
  }

  .statistics-chart-large-cols .ct-bar:nth-child(3) {
    stroke: #a0d568 !important;
  }

  .statistics-chart-large-cols .ct-bar:nth-child(4) {
    stroke: #fbce53 !important;
  }

  .statistics-chart-large-cols .ct-bar:nth-child(5) {
    stroke: #ed5564 !important;
  }
</style>

<script>
  import Api from '@/services/Api'
  import ChatApi from '@/services/ChatApi'
  import Utils from '@/services/Utils'
  import axios from 'axios'

  export default {
    data () {
      const user = Utils.getUser()
      const defaultDates = [Utils.getFormatDate(new Date(Date.now() - 86400000)), Utils.getFormatDate()]
      const salesTableHeaders = [
        { text: window.strings['id'], value: 'id', width: 100 },
        { text: window.strings['name'], value: 'name', align: 'left', width: 140 },
        { text: window.strings['email'], value: 'email', align: 'left', width: 120 },
        { text: window.strings['mobile_phone'], value: 'mobile_phone', align: 'center', width: 160 },
        { text: window.strings['country'], value: 'country', align: 'center', width: 120 },
        { text: window.strings['date'], value: 'date', align: 'center', width: 120 },
      ]
      const showSalesTableValues = Utils.hasPermission('statistics_new_sales_values')
      if (showSalesTableValues) {
        salesTableHeaders.push(
          { text: window.strings['value'], value: 'value', align: 'center', width: 90 },
          { text: window.strings['currency'], value: 'currency', align: 'center', width: 90 },
          { text: window.strings['transaction'], value: 'transaction', align: 'center', width: 130 },
        )
      }
      let showChatButton = false
      if (user && user.configurations && ((user.configurations.menu_items && user.configurations.menu_items.indexOf('chat') > -1) || user.configurations.chat) && Utils.hasPermission('chat_view')) {
        showChatButton = true
      }
      return {
        str: window.strings,
        datepickerLanguage: window.datepickerLanguage,
        user: user,
        showChatButton: showChatButton,
        showNewSales: Utils.hasPermission('statistics_new_sales'),
        showReturnSales: Utils.hasPermission('statistics_new_sales'),
        showServiceUse: Utils.hasPermission('statistics_service_use'),
        showClientsCountries: Utils.hasPermission('statistics_clients_countries'),
        showClientsAge: Utils.hasPermission('statistics_clients_ages'),
        showClientsPortugal: Utils.hasPermission('statistics_clients_portugal'),
        showAverageMessages: Utils.hasPermission('statistics_average_messages'),
        showAverageFeedbacks: Utils.hasPermission('statistics_average_feedbacks'),
        showAverageRegisters: Utils.hasPermission('statistics_average_registers'),
        showAverageValidations: Utils.hasPermission('statistics_average_validations'),
        showAverageEmployeesFeedbacks: Utils.hasPermission('statistics_average_employees_feedbacks'),
        showFeedbacksRating: Utils.hasPermission('statistics_feedbacks_rating'),
        showSalesTableValues: showSalesTableValues,
        charts: [],
        pieCharts: [],
        serviceUseSelectCharts: [],
        serviceUseSelectChartActive: 0,
        averageSelectCharts: [],
        averageSelectChartActive: 0,
        firstAverageChartDrawn: false,
        mapProcessing: true,
        salesTableHeaders: salesTableHeaders,
        newSalesTableUsers: [],
        newSalesCurrenciesTotalValuesStr: '',
        newSalesCurrenciesAverageValuesStr: '',
        newSalesTableSearch: '',
        newSalesTableLoading: true,
        returnSalesTableUsers: [],
        returnSalesCurrenciesTotalValuesStr: '',
        returnSalesCurrenciesAverageValuesStr: '',
        returnSalesTableSearch: '',
        returnSalesTableLoading: true,
        averageEmployeesFeedbacksTableHeaders: [
          { text: window.strings['employee_id'], value: 'employee_id', align: 'left', width: 120 },
          { text: window.strings['employee_name'], value: 'employee_name', align: 'center', width: 150 },
          { text: window.strings['time_avg'], value: 'time_avg', align: 'center', width: 150 },
          { text: window.strings['time_total'], value: 'time_total', align: 'center', width: 150 },
        ],
        averageEmployeesFeedbacksData: {
          table: [],
          total: {
            avg: 0,
            time: 0
          }
        },
        averageEmployeesFeedbacksTableSearch: '',
        averageEmployeesFeedbacksTableLoading: true,
        employeesDict: null,
        datesFilter: {
          new_sales: {
            menu: false,
            dates: defaultDates,
            text: defaultDates.join(' / '),
            callback: this.applyNewSalesFilter
          },
          return_sales: {
            menu: false,
            dates: defaultDates,
            text: defaultDates.join(' / '),
            callback: this.applyReturnSalesFilter
          },
          average_employees_feedbacks: {
            menu: false,
            dates: defaultDates,
            text: defaultDates.join(' / '),
            callback: this.applyAverageEmployeesFeedbacks
          },
          average: {
            menu: false,
            dates: defaultDates,
            text: defaultDates.join(' / '),
            callback: this.applyAverageFilter
          },
        },
        activeStatus: 0,
        chartTotal: {},
        feedbacksRatingData: null,
        dialogFeedbacksRating: false,
        feedbacksRatingClients: null,
        feedbacksRatingClientsHeaders: [
          { text: window.strings['id'], value: 'id', align: 'left' },
          { text: window.strings['name'], value: 'name', align: 'left' },
          { text: window.strings['actions'], value: 'actions', align: 'center' },
        ],
        feedbacksRatingClientsSearch: '',
      }
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.main()
    },
    methods: {
      main() {
        if (this.showNewSales) {
          this.drawSalesTable('newSales')
        }
        if (this.showReturnSales) {
          this.drawSalesTable('returnSales')
        }
        if (this.showServiceUse) {
          this.drawServiceUseChart()
        }
        if (this.showClientsCountries || this.showClientsAge || this.showClientsPortugal || this.showFeedbacksRating) {
          this.drawClientsCharts()
        }
        if (this.showAverageMessages) {
          this.drawMessagesAvgChart()
        }
        if (this.showAverageFeedbacks) {
          this.drawFeedbacksAvgChart()
        }
        if (this.showAverageRegisters) {
          this.drawRegistersAvgChart()
        }
        if (this.showAverageValidations) {
          this.drawValidationsAvgChart()
        }
        if (this.showAverageEmployeesFeedbacks) {
          this.drawAverageEmployeesFeedbacksTable()
        }
      },
      applyNewSalesFilter() {
        this.datesFilter['new_sales'].text = this.datesFilter['new_sales'].dates.join(' / ')
        this.datesFilter['new_sales'].menu = false
        this.drawSalesTable('newSales')
      },
      applyReturnSalesFilter() {
        this.datesFilter['return_sales'].text = this.datesFilter['return_sales'].dates.join(' / ')
        this.datesFilter['return_sales'].menu = false
        this.drawSalesTable('returnSales')
      },
      applyAverageFilter() {
        const chartActive = this.averageSelectCharts[this.averageSelectChartActive]
        this.firstAverageChartDrawn = false
        if (chartActive && chartActive.id === 'average_messages') {
          this.datesFilter.average.text = this.datesFilter.average.dates.join(' / ')
          this.datesFilter.average.menu = false
          this.drawMessagesAvgChart()
        }
        if (chartActive && chartActive.id === 'average_feedbacks') {
          this.datesFilter.average.text = this.datesFilter.average.dates.join(' / ')
          this.datesFilter.average.menu = false
          this.drawFeedbacksAvgChart()
        }
        if (chartActive && chartActive.id === 'average_registers') {
          this.datesFilter.average.text = this.datesFilter.average.dates.join(' / ')
          this.datesFilter.average.menu = false
          this.drawRegistersAvgChart()
        }
        if (chartActive && chartActive.id === 'average_validations') {
          this.datesFilter.average.text = this.datesFilter.average.dates.join(' / ')
          this.datesFilter.average.menu = false
          this.drawValidationsAvgChart()
        }
      },
      applyAverageEmployeesFeedbacks() {
        this.datesFilter['average_employees_feedbacks'].text = this.datesFilter['average_employees_feedbacks'].dates.join(' / ')
        this.datesFilter['average_employees_feedbacks'].menu = false
        this.drawAverageEmployeesFeedbacksTable()
      },
      drawSalesTable(key) {
        const self = this
        this[key + 'CurrenciesTotalValuesStr'] = ''
        this[key + 'CurrenciesAverageValuesStr'] = ''
        this[key + 'TableUsers'] = []
        this[key + 'TableLoading'] = true
        getSales(function (sales) {
          if (sales && sales.length) {
            if (self.showSalesTableValues) {
              getSalesPayments({
                ids: [sales[0].id, sales[sales.length - 1].id]
              },function (payments) {
                const paymentsObj = {}
                payments.forEach(function (p) {
                  paymentsObj[p.id] = p
                })
                const currenciesValues = {}
                for (let i = 0; i < sales.length; i++) {
                  sales[i].value = paymentsObj[sales[i].id] && paymentsObj[sales[i].id].value ? paymentsObj[sales[i].id].value : 0
                  sales[i].currency = paymentsObj[sales[i].id] && paymentsObj[sales[i].id].currency ? paymentsObj[sales[i].id].currency : '-'
                  sales[i].transaction = paymentsObj[sales[i].id] ? paymentsObj[sales[i].id].cha_id : window.strings['manual']
                  sales[i].date = Utils.getFormatDateHour(sales[i].date, true)
                  if (self.showSalesTableValues && sales[i].currency !== '-') {
                    if (!currenciesValues[sales[i].currency]) {
                      currenciesValues[sales[i].currency] = {
                        count: 0,
                        value: 0
                      }
                    }
                    currenciesValues[sales[i].currency].count += 1
                    currenciesValues[sales[i].currency].value += parseFloat(sales[i].value)
                  }
                }
                if (self.showSalesTableValues) {
                  let salesCurrenciesValues = []
                  for (const cur in currenciesValues) {
                    salesCurrenciesValues.push({
                      label: cur,
                      count: currenciesValues[cur].count,
                      value: currenciesValues[cur].value
                    })
                  }
                  salesCurrenciesValues = salesCurrenciesValues.sort((a, b) => a.label.localeCompare(b.label))
                  self[key + 'CurrenciesTotalValuesStr'] = salesCurrenciesValues.map(it => `${Math.round((it.value) * 10) / 10} ${it.label}`).join(' | ')
                  self[key + 'CurrenciesAverageValuesStr'] = salesCurrenciesValues.map(it => `${Math.round((it.value / it.count) * 10) / 10} ${it.label}`).join(' | ')
                }
                self[key + 'TableUsers'] = sales
                self[key + 'TableLoading'] = false
              })
            } else {
              for (let i = 0; i < sales.length; i++) {
                sales[i].date = Utils.getFormatDateHour(sales[i].date, true)
              }
              self[key + 'TableUsers'] = sales
              self[key + 'TableLoading'] = false
            }
          } else {
            self[key + 'TableLoading'] = false
          }
        })

        function getSales(callback) {
          const requestKey = key === 'newSales' ? 'getClientsNewSales' : 'getClientsReturnSales'
          const datesFilterKey = key === 'newSales' ? 'new_sales' : 'return_sales'
          Api[requestKey]({
            start_date: self.datesFilter[datesFilterKey].dates[0] + ' 00:00:00',
            end_date: self.datesFilter[datesFilterKey].dates[1] + ' 23:59:59',
          }, function (response) {
            if (response.success) {
              callback(response.data)
            } else {
              self[key + 'TableLoading'] = false
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function getSalesPayments(payload, callback) {
          Api.getClientsSalesPayments(payload, function (response) {
            if (response.success) {
              callback(response.data)
            } else {
              self[key + 'TableLoading'] = false
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }
      },
      exportSalesTable (key) {
        Utils.exportCsv({
          title: window.strings['emails'],
          hideHeaders: true,
          headers: this.salesTableHeaders,
          data: this[key + 'TableUsers']
        })
      },
      drawServiceUseChart() {
        const self = this
        Api.getStatisticServiceUse(function (response) {
          if (response.success) {
            const charts = []
            for (let i = 0; i < response.data.length; i++) {
              const src = {}
              const total = response.data[i].data.reduce((accumulator, currentValue) => {
                return accumulator + currentValue.count;
              }, 0);
              response.data[i].data.forEach(function (item) {
                src[item.value] = Math.round((item.count * 100) / total)
              })
              charts.push(self.getChartData({
                id: i,
                type: 'Bar',
                title: response.data[i].title,
                data: src,
                replaceTitle: true,
                verticalBar: true,
                percentage: true,
                legend: {
                  valueX: ' ' + response.data[i].title.split(' - ')[1].toLowerCase().trim(),
                  valueY: '%'
                }
              }))
            }
            if (charts.length) {
              self.drawCharts({
                key: 'serviceUseSelectCharts',
                data: charts,
                percentage: true,
                axisTitles: {
                  x: window.strings['weeks_months'],
                  y: window.strings['clients'].toLowerCase() + ' %'
                }
              })
            }
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      drawMessagesAvgChart() {
        const self = this
        if (this.firstAverageChartDrawn) {
          draw({})
        } else {
          this.firstAverageChartDrawn = true
          ChatApi.getStatisticMessagesAvg({
            start_date: self.datesFilter.average.dates[0],
            end_date: self.datesFilter.average.dates[1]
          }, function (response) {
            if (response.success) {
              const src = {}
              let avgTotal = 0
              let countTotal = 0
              response.data.forEach(function (item, index) {
                avgTotal += item.avg
                countTotal += item.count
                src[index] = {
                  value: item.avg,
                  extraValue: item.count,
                  extraLabel: item.hour + (item.half ? '.30' : ''),
                  fixedMeta: item.hour + 'h' + (item.half ? '30' : '') + '\n' + window.strings['total'] + ': ' + item.count + '\n' + window.strings['average'] + ': ' + item.avg
                }
              })
              draw(src, {
                avg: avgTotal,
                count: countTotal
              })
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function draw(src, total) {
          self.drawCharts({
            key: 'averageSelectCharts',
            total: {
              id: 'average_messages',
              value: total
            },
            sort: true,
            data: [self.getChartData({
              id: 'average_messages',
              sort: 1,
              type: 'Line',
              title: window.strings['average_messages'],
              data: src,
              replaceTitle: true,
              verticalBar: true,
              percentage: false,
              halfHour: true,
              legend: {
                valueX: '',
                valueY: ''
              }
            })],
            percentage: false,
            maxOffset: 20,
            axisTitles: {
              x: window.strings['hours'],
              y: window.strings['messages']
            }
          })
        }
      },
      drawFeedbacksAvgChart() {
        const self = this
        if (this.firstAverageChartDrawn) {
          draw({})
        } else {
          this.firstAverageChartDrawn = true
          Api.getStatisticFeedbacksAvg({
            start_date: self.datesFilter.average.dates[0],
            end_date: self.datesFilter.average.dates[1]
          }, function (response) {
            if (response.success) {
              const src = {}
              response.data.forEach(function (item, index) {
                src[index] = {
                  value: item.avg,
                  extraValue: item.count,
                  extraLabel: item.hour + (item.half ? '.30' : ''),
                  fixedMeta: item.hour + 'h' + (item.half ? '30' : '') + '\n' + window.strings['total'] + ': ' + item.count + '\n' + window.strings['average'] + ': ' + item.avg
                }
              })
              draw(src)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function draw(src) {
          self.drawCharts({
            key: 'averageSelectCharts',
            sort: true,
            data: [self.getChartData({
              id: 'average_feedbacks',
              sort: 2,
              type: 'Line',
              title: window.strings['average_feedbacks'],
              data: src,
              replaceTitle: true,
              verticalBar: true,
              percentage: false,
              halfHour: true,
              legend: {
                valueX: '',
                valueY: ''
              }
            })],
            percentage: false,
            maxOffset: 2,
            axisTitles: {
              x: window.strings['hours'],
              y: window.strings['feedbacks']
            }
          })
        }
      },
      drawRegistersAvgChart() {
        const self = this
        if (this.firstAverageChartDrawn) {
          draw({})
        } else {
          this.firstAverageChartDrawn = true
          Api.getStatisticRegistersAvg({
            start_date: self.datesFilter.average.dates[0],
            end_date: self.datesFilter.average.dates[1]
          }, function (response) {
            if (response.success) {
              const src = {}
              response.data.forEach(function (item, index) {
                src[index] = {
                  value: item.avg,
                  extraValue: item.count,
                  extraLabel: item.hour + (item.half ? '.30' : ''),
                  fixedMeta: item.hour + 'h' + (item.half ? '30' : '') + '\n' + window.strings['total'] + ': ' + item.count + '\n' + window.strings['average'] + ': ' + item.avg
                }
              })
              draw(src)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function draw(src) {
          self.drawCharts({
            key: 'averageSelectCharts',
            sort: true,
            data: [self.getChartData({
              id: 'average_registers',
              sort: 3,
              type: 'Line',
              title: window.strings['average_registers'],
              data: src,
              replaceTitle: true,
              verticalBar: true,
              percentage: false,
              halfHour: true,
              legend: {
                valueX: '',
                valueY: ''
              }
            })],
            percentage: false,
            maxOffset: 1,
            axisTitles: {
              x: window.strings['hours'],
              y: window.strings['registers']
            }
          })
        }
      },
      drawValidationsAvgChart() {
        const self = this
        if (this.firstAverageChartDrawn) {
          draw({})
        } else {
          this.firstAverageChartDrawn = true
          Api.getStatisticValidationsAvg({
            start_date: self.datesFilter.average.dates[0],
            end_date: self.datesFilter.average.dates[1]
          }, function (response) {
            if (response.success) {
              const src = {}
              response.data.forEach(function (item, index) {
                src[index] = {
                  value: item.avg,
                  extraValue: item.count,
                  extraLabel: item.hour + (item.half ? '.30' : ''),
                  fixedMeta: item.hour + 'h' + (item.half ? '30' : '') + '\n' + window.strings['total'] + ': ' + item.count + '\n' + window.strings['average'] + ': ' + item.avg
                }
              })
              draw(src)
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }

        function draw(src) {
          self.drawCharts({
            key: 'averageSelectCharts',
            sort: true,
            data: [self.getChartData({
              id: 'average_validations',
              sort: 4,
              type: 'Line',
              title: window.strings['average_validations'],
              data: src,
              replaceTitle: true,
              verticalBar: true,
              percentage: false,
              halfHour: true,
              legend: {
                valueX: '',
                valueY: ''
              }
            })],
            percentage: false,
            maxOffset: 1,
            axisTitles: {
              x: window.strings['hours'],
              y: window.strings['validated_registers']
            }
          })
        }
      },
      drawAverageEmployeesFeedbacksTable() {
        const self = this
        this.averageEmployeesFeedbacksTableLoading = true
        Api.getStatisticEmployeesFeedbacksAvg({
          start_date: self.datesFilter['average_employees_feedbacks'].dates[0],
          end_date: self.datesFilter['average_employees_feedbacks'].dates[1]
        }, function (response) {
          if (response.success) {
            self.getEmployees(function () {
              self.averageEmployeesFeedbacksData.total.avg = convertMinutesToHours(response.data.employees_time_avg)
              self.averageEmployeesFeedbacksData.total.time = convertMinutesToHours(response.data.employees_time_total)
              const tableRows = []
              response.data.employees_data.forEach(function (row) {
                tableRows.push({
                  employee_id: row.employee_id,
                  employee_name: self.employeesDict[row.employee_id] ? self.employeesDict[row.employee_id].name : row.employee_id,
                  time_avg: row.time_avg,
                  time_avg_str: convertMinutesToHours(row.time_avg),
                  time_total: row.time_total,
                  time_total_str: convertMinutesToHours(row.time_total)
                })
              })
              self.averageEmployeesFeedbacksData.table = tableRows
              self.averageEmployeesFeedbacksTableLoading = false
            })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })

        function convertMinutesToHours(minutes) {
          const hours = Math.floor(minutes / 60)
          const remainingMinutes = minutes % 60
          if (hours === 0) {
            return remainingMinutes + 'min'
          } else if (remainingMinutes === 0) {
            return hours + 'h'
          } else {
            return hours + 'h ' + remainingMinutes + 'min'
          }
        }
      },
      getEmployees: function (callback) {
        const self = this
        if (this.employeesDict) {
          return callback()
        }
        Api.getEmployees({
          fields: ['id', 'name']
        },function (response) {
          self.$isLoading(false)
          if (response.success) {
            const dict = {}
            dict[Math.abs(axios.defaults.ptId)] = {
              name: 'Admin'
            }
            response.data.forEach(function (emp) {
              dict[emp.id] = emp
            })
            self.employeesDict = dict
            callback()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      drawClientsCharts() {
        const self = this
        const fields = []
        if (this.showClientsCountries) {
          fields.push('country')
        }
        if (this.showClientsAge) {
          fields.push('birth_date')
        }
        if (this.showClientsPortugal) {
          fields.push('locality')
        }
        if (this.showFeedbacksRating) {
          fields.push('db_id')
          fields.push('id')
          fields.push('chat_id')
          fields.push('name')
          fields.push('feedback_rating')
        }
        Api.getClients({
          status: this.activeStatus,
          fields: fields
        }, function (response) {
          if (response.success) {
            try {
              const countriesObj = {}
              const localitiesObj = {}
              const agesObj = {}
              const feedbacksRatingObj = {}
              const charts = []
              if (self.showFeedbacksRating) {
                const feedbackRatingItems = Utils.getFeedbackRating()
                if (feedbackRatingItems) {
                  feedbackRatingItems.forEach(function (frItem) {
                    feedbacksRatingObj[frItem.value] = {
                      labelStr: frItem.status,
                      value: 0,
                      fixedMeta: '',
                      clients: []
                    }
                  })
                }
              }
              response.data.forEach(function (client) {
                if (self.showClientsCountries && client.country) {
                  if (!countriesObj[client.country]) {
                    countriesObj[client.country] = 0
                  }
                  countriesObj[client.country] += 1
                }
                if (self.showClientsPortugal && /^\d+$/.test(client.locality.replace('-', ''))) {
                  if (!localitiesObj[client.locality]) {
                    localitiesObj[client.locality] = 0
                  }
                  localitiesObj[client.locality] += 1
                }
                if (self.showClientsAge && client.birth_date) {
                  const ageDifMs = Date.now() - new Date(client.birth_date).getTime()
                  const age = Math.abs((new Date(ageDifMs)).getUTCFullYear() - 1970)
                  if (!isNaN(age) && age > 10) {
                    if (!agesObj[age]) {
                      agesObj[age] = 0
                    }
                    agesObj[age] += 1
                  }
                }
                if (self.showFeedbacksRating && client.feedback_rating) {
                  const ratingValue = Math.ceil(client.feedback_rating)
                  feedbacksRatingObj[ratingValue].value += 1
                  feedbacksRatingObj[ratingValue].fixedMeta = feedbacksRatingObj[ratingValue].labelStr + ': ' + feedbacksRatingObj[ratingValue].value.toString()
                  feedbacksRatingObj[ratingValue].clients.push(client)
                }
              })
              if (self.showClientsCountries) {
                charts.push(self.getChartData({
                  id: 1,
                  type: 'Bar',
                  title: window.strings['country'],
                  data: countriesObj,
                  useLabelKey: true,
                  leftOffset: true
                }))
              }
              if (self.showClientsAge) {
                charts.push(self.getChartData({
                  id: 2,
                  type: 'Bar',
                  title: window.strings['age'],
                  data: agesObj,
                  useLabelKey: true
                }))
              }
              if (self.showFeedbacksRating) {
                self.feedbacksRatingData = feedbacksRatingObj
                charts.push(self.getChartData({
                  id: 3,
                  type: 'Bar',
                  title: window.strings['feedbacks_rating_chart'],
                  replaceTitle: true,
                  verticalBar: true,
                  maxOffset: 1,
                  cl: 'statistics-chart-large-cols statistics-chart-feedbacks-rating',
                  data: feedbacksRatingObj
                }))
              }
              if (charts.length) {
                self.drawCharts({
                  key: 'charts',
                  data: charts
                })
              }
              if (self.showClientsPortugal) {
                const localitiesList = []
                for (const key in localitiesObj) {
                  localitiesList.push({
                    postalCode: key,
                    qty: localitiesObj[key]
                  })
                }
                drawLocalitiesMap(localitiesList)
              }

              async function drawLocalitiesMap(localities) {
                try {
                  if (!document.getElementById('map-localities')) {
                    return false
                  }

                  if (!document.querySelector('script[src="https://unpkg.com/leaflet@1.6.0/dist/leaflet.js"]')) {
                    const mapJsScript = document.createElement('script')
                    mapJsScript.src = 'https://unpkg.com/leaflet@1.6.0/dist/leaflet.js'
                    document.getElementsByTagName('head')[0].appendChild(mapJsScript)
                  }
                  if (!document.querySelector('link[href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"]')) {
                    const mapCssLink = document.createElement('link')
                    mapCssLink.href = 'https://unpkg.com/leaflet@1.6.0/dist/leaflet.css';
                    mapCssLink.rel = 'stylesheet'
                    document.getElementsByTagName('head')[0].appendChild(mapCssLink)
                  }

                  setTimeout(function () {
                    const Locations = () => import('@/locations/locations.json')
                    Locations().then(function (data) {
                      const element = document.getElementById('map-localities')
                      if (!element) {
                        return false
                      }
                      element.style = 'width: 100%; height: 500px; margin-bottom: 40px;'

                      const southWest = L.latLng(36.97, -9.79)
                      const northEast = L.latLng(42.15, -6.19)
                      const bounds = L.latLngBounds(southWest, northEast)
                      const map = L.map('map-localities', {
                        maxBounds: bounds,
                        center: bounds.getCenter(),
                        zoom: 7
                      })

                      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {}).addTo(map)
                      map.fitBounds(bounds)

                      if (localities.length) {
                        const citiesCount = {}
                        let citiesCountTotal = 0
                        localities.forEach(function (item) {
                          const location = data[item.postalCode.replace(/\D/g, '')]
                          if (location) {
                            L.circleMarker([location.lat, location.lon], {
                              color: '#3388ff'
                            }).addTo(map)

                            const city = foundCity(Math.abs(location.lat), Math.abs(location.lon))
                            if (city) {
                              if (!citiesCount[city]) {
                                citiesCount[city] = 0
                              }
                              citiesCount[city] += 1
                              citiesCountTotal += 1
                            }
                          }
                        })

                        const pieLabels = []
                        const pieSeries = []
                        for (const key in citiesCount) {
                          const val = parseInt((citiesCount[key] * 100) / citiesCountTotal)
                          pieLabels.push(key)
                          pieSeries.push({
                            value: val,
                            meta: key + ' - ' + val + '%'
                          })
                        }
                        self.pieCharts.push({
                          title: window.strings['percentage_clients_portugal'],
                          type: 'Pie',
                          color: 'blue-grey darken-3',
                          data: {
                            labels: pieLabels,
                            series: pieSeries
                          },
                          options: {
                            height: 400,
                            plugins: [
                              self.$chartist.plugins.tooltip({
                                appendToBody: true,
                              })
                            ]
                          },
                          size: '400',
                          sizelimit: '400',
                        })
                      }

                      self.mapProcessing = false
                    })
                  }, 1000)

                  function foundCity(lat, lon) {
                    const citiesOffset = 0.2
                    const cities = {
                      'Aveiro': [[40.9435, 40.4145], [8.3109, 8.9162]],
                      'Beja': [[38.3694, 37.7437], [7.5459, 7.9219]],
                      'Braga': [[41.8252, 41.3247], [8.1835, 8.6884]],
                      'Bragança': [[41.9666, 41.1929], [6.6503, 7.4283]],
                      'Castelo Branco': [[40.2599, 39.6759], [7.0066, 7.8737]],
                      'Coimbra': [[40.4155, 39.6337], [8.1113, 8.7241]],
                      'Évora': [[38.9256, 38.2748], [7.8921, 7.1777]],
                      'Faro': [[37.3733, 36.8709], [8.3573, 7.3544]],
                      'Guarda': [[40.8796, 40.3357], [7.0774, 7.6621]],
                      'Leiria': [[40.2405, 39.8433], [8.7807, 8.9957]],
                      'Lisboa': [[39.0959, 38.6292], [9.2316, 8.7382]],
                      'Portalegre': [[39.2953, 38.6017], [7.7492, 7.1699]],
                      'Porto': [[41.4012, 41.0057], [8.6793, 8.0646]],
                      'Santarém': [[39.4468, 38.6224], [8.9796, 8.0973]],
                      'Setúbal': [[38.7928, 38.2349], [9.2585, 8.6380]],
                      'Viana do Castelo': [[41.9821, 41.4731], [8.8644, 8.3013]],
                      'Vila Real': [[41.8743, 41.1474], [7.7799, 7.1837]],
                      'Viseu': [[41.2595, 40.5157], [7.9986, 7.3266]]
                    }
                    for (const key in cities) {
                      const lats = cities[key][0]
                      const longs = cities[key][1]
                      if (lat >= lats[1] - citiesOffset && lat <= lats[0] + citiesOffset &&
                        lon >= longs[1] - citiesOffset && lon <= longs[0] + citiesOffset
                      ) {
                        return key
                      }
                    }
                    return null
                  }
                } catch (error) {}
              }
            } catch (error) {}
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getChartData(args) {
        const id = args.id
        const sort = args.sort
        const type = args.type
        const title = args.title
        const filter = args.filter
        const obj = args.data
        const replaceTitle = args.replaceTitle
        const legend = args.legend
        const verticalBar = args.verticalBar
        const percentage = args.percentage
        const leftOffset = args.leftOffset
        const halfHour = args.halfHour
        const useLabelKey = args.useLabelKey
        const cl = args.cl
        const maxOffset = args.maxOffset
        let items = []
        let total = 0
        for (const key in obj) {
          const meta = obj[key].meta ? obj[key].meta : key
          const value = obj[key].value || obj[key].value === 0 ? obj[key].value : obj[key]
          items.push({
            label: key,
            labelStr: useLabelKey ? key : (obj[key] && obj[key].labelStr ? obj[key].labelStr : null),
            meta: obj[key].fixedMeta ? obj[key].fixedMeta : (meta + (legend && legend.valueX ? legend.valueX : '') + ': ' + value + (legend && legend.valueY ? legend.valueY : '')),
            extraLabel: obj[key].extraLabel,
            value: value,
            extraValue: obj[key].extraValue,
          })
          total += parseInt(value)
        }
        items = items.sort((a, b) => a.label - b.label)
        const labels = []
        let labelNumber = -1
        items.forEach(function (it, ind) {
          if (it.labelStr) {
            labels.push(it.labelStr)
          } else {
            if (!halfHour || (halfHour && ind % 2 === 0)) {
              labelNumber += 1
              labels.push(labelNumber.toString())
            } else {
              labels.push('')
            }
          }
        })
        let size = type === 'Line' ? 400 : items.length * 40
        size = size > 100 ? size : 100
        return {
          id: id,
          sort: sort,
          type: type,
          horizontal: !verticalBar ? true : false,
          title: replaceTitle ? title : window.strings['clients'] + ' - ' + title + ' (' + total + ')',
          filter: filter,
          color: 'blue-grey darken-3',
          labels: labels,
          series: [items],
          size: percentage ? '400' : size.toString(),
          sizelimit: percentage ? '400' : size.toString(),
          leftOffset: leftOffset,
          cl: cl,
          maxOffset: maxOffset
        }
      },
      drawCharts(args) {
        const data = args.data
        const key = args.key
        const total = args.total
        const sort = args.sort
        const axisTitles = args.axisTitles
        const percentage = args.percentage
        const maxOffset = args.maxOffset ? args.maxOffset : 10
        const charts = []
        for (let i = 0; i < data.length; i++) {
          data[i].options = {}
          if (data[i].type !== 'Pie') {
            let max = 0
            data[i].series.forEach(function (row) {
              row.forEach(function (point) {
                max = point.value > max ? point.value : max
              })
            })
            data[i].options = {
              lineSmooth: this.$chartist.Interpolation.cardinal({
                tension: 0,
              }),
              low: 0,
              high: percentage ? 100 : (data[i].maxOffset ? (max + data[i].maxOffset) : (max + maxOffset)),
              chartPadding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              },
              plugins: axisTitles ? [
                this.$chartist.plugins.tooltip({
                  appendToBody: true,
                }),
                this.$chartist.plugins.ctAxisTitle({
                  axisX: {
                    axisTitle: axisTitles.x,
                    axisClass: 'statistics-ct-axis-title',
                    offset: {
                      x: 0,
                      y: 50
                    },
                    textAnchor: 'middle'
                  },
                  axisY: {
                    axisTitle: axisTitles.y,
                    axisClass: 'statistics-ct-axis-title',
                    offset: {
                      x: 0,
                      y: 10
                    },
                    flipTitle: true
                  }
                })
              ] : [
                this.$chartist.plugins.tooltip({
                  appendToBody: true,
                })
              ]
            }
            if (data[i].horizontal) {
              data[i].options.horizontalBars = true
              if (data[i].leftOffset) {
                data[i].options.axisY = {
                  offset: 120
                }
              }
            }
            if (axisTitles) {
              data[i].options.axisX = {
                offset: 60
              }
              data[i].options.axisY = {
                offset: 80
              }
            }
            if (!data[i].options.axisY) {
              data[i].options.axisY = {}
            }
            data[i].options.axisY.onlyInteger = true
          }
          charts.push(data[i])
        }
        let newCharts = JSON.parse(JSON.stringify(this[key]))
        const chartsIds = this[key].map(a => a.id)
        charts.forEach(function (ch) {
          const index = chartsIds.indexOf(ch.id)
          if (index === -1) {
            newCharts.push(ch)
          } else {
            newCharts[index] = ch
          }
        })
        if (sort) {
          newCharts = newCharts.sort((a, b) => a.sort - b.sort)
          for (let i = 0; i < newCharts.length; i++) {
            newCharts[i].index = i
          }
        }
        this[key] = newCharts
        if (total) {
          this.chartTotal[total.id] = total.value
        }
        this.afterDrawCharts(key)
      },
      afterDrawCharts(key) {
        const self = this
        try {
          if (key === 'charts') {
            if (this.showFeedbacksRating) {
              setTimeout(function () {
                const feedbacksRatingChart = document.getElementsByClassName('statistics-chart-feedbacks-rating')
                if (feedbacksRatingChart && feedbacksRatingChart[0]) {
                  feedbacksRatingChart[0].removeEventListener('click', self.handleChartFeedbacksRatingClick)
                  feedbacksRatingChart[0].addEventListener('click', self.handleChartFeedbacksRatingClick)
                }
              })
            }
          }
        } catch { }
      },
      handleChartFeedbacksRatingClick(evt) {
        try {
          if (evt.target && evt.target.classList && evt.target.classList.contains('ct-bar')) {
            const childrens = Array.prototype.slice.call(evt.target.parentNode.children)
            const index = childrens.indexOf(evt.target)
            if (this.feedbacksRatingData && this.feedbacksRatingData[index + 1]) {
              this.feedbacksRatingClientsSearch = ''
              this.feedbacksRatingClients = {
                title: this.feedbacksRatingData[index + 1].labelStr,
                table: this.feedbacksRatingData[index + 1].clients
              }
              this.dialogFeedbacksRating = true
            }
          }
        } catch { }
      },
      closeFeedbacksRatingDialog() {
        this.dialogFeedbacksRating = false
        this.feedbacksRatingClients = null
      },
      openClient: function (item) {
        const self = this
        this.$isLoading(true)
        Api.getUserWithId({
          id: item.id
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            const client = Utils.encodeClient(response.data[0])
            Utils.setStorage('client', client)
            window.showPaydayAlert = true
            window.clientPageCacheList = null
            self.$router.push({ path: '/home/client', query: { tab: 'details' } })
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      openClientChat: function (item) {
        Utils.openClientChat({
          client: item
        })
      },
      exportAverageSelectChart: function () {
        try {
          Utils.exportCsv({
            title: this.averageSelectCharts[this.averageSelectChartActive].title,
            headers: [{
              value: 'extraLabel',
              text: window.strings['hour']
            }, {
              value: 'value',
              text: window.strings['average']
            }, {
              value: 'extraValue',
              text: window.strings['total']
            }],
            data: this.averageSelectCharts[this.averageSelectChartActive].series[0]
          })
        } catch (error) {
          this.$alert(
            window.strings['common_error'],
            '',
            'warning',
            Utils.getAlertOptions()
          )
        }
      },
    },
  }
</script>
